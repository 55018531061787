<template>
	<div v-if="positions && positions.length">
		<h3 class="text-center mb-4">{{title}}</h3>
		<div class="space-y-1">
			<div 
				v-for="(position, index) in positions.slice(0, settings.positions )"
				:key="'key_'+position.user.username+'_'+type"
				class="flex items-center space-x-4 bg-beige-dark p-2 overflow-hidden ">
				
				<div class="flex-none w-8 text-2xl font-bold text-center opacity-75">
					{{ index+1 }}
				</div>
				
				<div class="flex-none w-12 h-12 rounded-full overflow-hidden">
					<img 
						v-if="position.user.picture"
						:src="fileKeyToUrl( position.user.picture )"
						alt="Logo"
						class="object-cover w-full h-full"
					/>
					<i 
						v-else
						class="fa fa-user fa-user-tie bg-white w-full h-full items-center justify-center flex text-xl" 
					/>
				</div>
				
				<div class="flex-grow truncate text-gray-darkest">
					{{position.user.given_name || position.user.given_name ? position.user.given_name + ' ' + position.user.family_name : position.user.email}}
				</div>
				
				<div class="flex-none space-x-4 flex">
					<div 
						v-if="leaderboard[ position.user.username ].kudos.heart"
						class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
						
						<i 
							class="fas w-6 text-center fa fa-heart" 
						/>
						<div>
							{{leaderboard[ position.user.username ].kudos.heart}}
						</div>
					</div>
					
					<div 
						v-if="leaderboard[ position.user.username ].kudos.thumbsUp"
						class="rounded-full flex items-center justify-center space-x-1 px-2 h-8 bg-white">
						
						<i 
							class="fas w-6 text-center fa fa-thumbs-up" 
						/>
						<div>
							{{leaderboard[ position.user.username ].kudos.thumbsUp}}
						</div>
					</div>
				</div>
				
				<div class="flex-none text-right pr-6">
					<div 
						v-if="type == 'products'"
						class="">
						
						kr {{currencyFormat(position.sum)}}
					</div>
					<div 
						v-if="type == 'tasks'"
						class="">
						
						{{ position.qty }} oppgaver
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			settings: {
				type: Object,
				required: true,
			},
			type: {
				type: String,
				required: true,
				default: 'products', 
			},
			users: {
				type: Array,
				required: true,
			},
			title: {
				type: String,
				required: true,
			},
			leaderboard: {
				type: Object,
				required: true,
			},
		},
		
		computed:{
			positions(){
				const positions = Object.keys( this.leaderboard );
				
				return positions
					.map( username => {
						const user = this.users.find( u => u.username == username);
						
						return {
							user,
							...this.leaderboard[ username ][ this.type ],
						}
					})
					.filter( position => {
						// console.log(position);
						
						if (this.type == 'products'){
							return position.sum ? true : false
						}
						else if (this.type == 'tasks'){
							return position.qty ? true : false
						}
					})
					.sort((a, b) => {
						if (this.type == 'products'){
							return b.sum > a.sum ? 1 : -1
						}
						else if (this.type == 'tasks'){
							return b.qty > a.qty ? 1 : -1
						}
					})
			}
		},
		
		data(){
			return {
				// 
			}
		},
		
		methods: {
			// 
		}
	}
</script>
